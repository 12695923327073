<template>
  <div id="pickup-order-detail">
    <van-nav-bar
      :title="$t('pickup_order.pickup_order')"
      left-arrow
      @click-left="() => $router.back()"
    />
    <van-pull-refresh v-model="refreshing" @refresh="getOrder">
      <template v-if="pickup_order">
        <van-cell
          :title="$t('pickup_order.id')"
          :value="pickup_order.id"
        />
        <van-cell
          :title="$t('pickup_order.status')"
          :value="$t(`pickup_order.statuses.${pickup_order.status}`)"
        />
        <van-cell
          :title="$t('pickup_order.price')"
        >
          <template v-if="pickup_order.price">
            {{ pickup_order.price | currency }}
          </template>
          <template v-else>
            {{ $t('pickup_order.free') }}
          </template>
        </van-cell>
        <van-cell
          :title="$t('pickup_order.pickup_date')"
          :value="pickup_order.date"
        />
        <van-cell
          :title="$t('pickup_order.pickup_time')"
          :value="pickup_order.time_period"
        />
        <van-cell
          :title="$t('pickup_order.pickup_address')"
          :value="pickup_order.address"
        />
        <van-cell
          :title="$t('pickup_order.contact')"
          :value="pickup_order.contact"
        />
        <van-cell
          :title="$t('pickup_order.phone')"
          :value="pickup_order.phone"
        />
        <van-cell
          :title="$t('pickup_order.remark')"
          :value="pickup_order.remark"
        />
        <van-cell
          :title="$t('pickup_order.contai_order')"
        >
          <template #label>
            <div class="row">
              <div class="col-6 mb-2" v-for="order in pickup_order.orders" :key="order.id">
                <div class="order-list" >
                  <router-link :to="{ name: 'order-detail', params: { id: order.id }}">{{ order.reference_id }}</router-link>
                  <br />
                  {{ order.receiver_address.name }}
                  <br />
                  {{ order.receiver_address.phone }}
                  <br />
                  {{ order.receiver_address.district }}
                </div>
              </div>
            </div>
          </template>
        </van-cell>
        <van-submit-bar
          class="payment-bar"
          :price="price"
          currency="HK$"
          text-align="left"
          :label="$t('pickup_order.price')+':'"
          :button-text="$t('payment.wechatpay')"
          button-type="primary"
        >
          <template #button>
            <van-button type="danger" v-if="cancelable" @click="cancel" round>{{ $t('order.cancel') }}</van-button>
            <van-button class="ml-1" v-if="paymentable" type="primary" round @click="wechatpay" :disabled="paymenting">{{ $t('payment.wechatpay') }}</van-button>
            <span style="color: red;" v-if="pickup_order.status === 2">{{ $t('pickup_order.picking_tip') }}</span>
          </template>
        </van-submit-bar>
      </template>
    </van-pull-refresh>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  NavBar, SubmitBar, NoticeBar, PullRefresh,
} from 'vant';
import { showErrorMessage } from '@/helpers';

Vue.use(NavBar);
Vue.use(PullRefresh);
Vue.use(SubmitBar);
Vue.use(NoticeBar);

/* global wx */

export default {
  name: 'PickupOrderDetail',
  mounted() {
    this.$api.app.jsSdk().then(({ data: response }) => {
      wx.config({
        debug: response.debug,
        appId: response.appId,
        timestamp: response.timestamp,
        nonceStr: response.nonceStr,
        signature: response.signature,
        jsApiList: response.jsApiList,
      });
    });
  },
  data() {
    return {
      paymenting: false,
      refreshing: false,
      pickup_order: null,
    };
  },
  computed: {
    price() {
      if (this.pickup_order) {
        return this.pickup_order.price ? this.pickup_order.price * 100 : this.$t('pickup_order.free');
      }
      return 0;
    },
    paymentable() {
      if (this.pickup_order) {
        return this.pickup_order.status === 0 && this.pickup_order.price > 0;
      }
      return false;
    },
    cancelable() {
      if (this.pickup_order) {
        return !~[2, 3, 9].indexOf(this.pickup_order.status);
      }
      return false;
    },
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.getOrder();
      },
      immediate: true,
    },
  },
  methods: {
    getOrder() {
      this.$toast.loading({
        message: this.$t('common.loading'),
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      this.$api.pickupOrder.getById(this.$route.params.id).then(({ data: response }) => {
        this.pickup_order = response.data;
        this.refreshing = false;
        if (this.paymentable) {
          this.wechatpay();
        }
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        this.$toast.clear();
      });
    },
    cancel() {
      this.$dialog.confirm({
        message: this.$t('pickup_order.cancel_comfirm'),
      }).then(() => {
        this.$api.pickupOrder.remove(this.$route.params.id).then(() => {
          this.$toast.success(this.$t('pickup_order.order_canceled'));
          this.$router.push({ name: 'pickup-orders' });
        }).catch((e) => {
          showErrorMessage(e);
        });
      }).catch(() => {});
    },
    wechatpay() {
      wx.checkJsApi({
        jsApiList: ['chooseWXPay'],
        success: ({ checkResult }) => {
          if (checkResult.chooseWXPay) {
            this.paymenting = true;
            this.$api.order.wechatpay(this.$route.params.id, { type: 'pickup_order' }).then(({ data: response }) => {
              wx.chooseWXPay({
                timestamp: response.timeStamp,
                nonceStr: response.nonceStr,
                package: response.package,
                signType: response.signType,
                paySign: response.paySign,
                success: () => {
                  // res: { errMsg: 'chooseWXPay:ok' }
                  this.$toast.loading({
                    message: this.$t('common.loading'),
                    forbidClick: true,
                    duration: 0,
                    overlay: true,
                  });
                  setTimeout(() => {
                    this.$api.order.wechatpayQuery(this.$route.params.id, { type: 'pickup_order' }).then(({ data: resp }) => {
                      this.pickup_order = resp.data;
                    }).catch((e) => {
                      showErrorMessage(e);
                    }).finally(() => {
                      this.paymenting = false;
                      this.$toast.clear();
                    });
                  }, 3000);
                },
                cancel: () => {
                  this.paymenting = false;
                },
                fail: () => {
                  this.paymenting = false;
                  // this.$toast.fail(this.$t('payment.payment_fail'));
                },
              });
            }).catch((e) => {
              showErrorMessage(e);
            });
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
  @import "../../style/variables";
  #pickup-order-detail {
    padding-bottom: 30px;
    .order-list {
      padding: .5rem;
      border: 1px dashed $gray-4;
    }
  }
</style>
