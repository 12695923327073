<template>
  <div id="pickup-order-list">
    <van-nav-bar
      :title="$t('pickup_order.order_list')"
      left-arrow
      @click-left="() => $router.back()"
    />
    <van-search
      v-model="search_parcel_code"
      :placeholder="$t('order.input_parcel_code_to_search')"
      show-action
      :action-text="$t('common.cancel')"
      @search="onLoad"
      @cancel="onCancelSearch"
    />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.not_more')"
        @load="onLoad"
      >
        <van-cell-group
          class="order-cell"
          v-for="po in list"
          :key="po.id"
        >
          <van-cell :to="{ name: 'pickup-order-detail', params: { id: po.id }}" is-link>
            <ul class="pickup-order-info">
              <li>
                <span class="title">{{ $t('pickup_order.id') }}</span>
                {{ po.id }}
              </li>
              <li>
                <span class="title">{{ $t('pickup_order.pickup_address') }}</span>
                {{ po.address }}
              </li>
              <li>
                <span class="title">{{ $t('pickup_order.contact') }}</span>
                {{ po.contact }}
              </li>
              <li>
                <span class="title">{{ $t('pickup_order.phone') }}</span>
                {{ po.phone }}
              </li>
              <li>
                <span class="title">{{ $t('pickup_order.status') }}</span>
                {{ $t(`pickup_order.statuses.${po.status}`) }}
                <template v-if="po.status === 9 && po.pay_time">
                  （{{ po.refund_status ? $t('payment.refunded') : $t('payment.refunding') }}）
                </template>
              </li>
              <li v-if="po.status !== 9">
                <span class="title">{{ $t('pickup_order.contai_order') }}</span>
                {{ po.orders.length }}
              </li>
              <li>
                <span class="title">{{ $t('pickup_order.price') }}</span>
                <template v-if="po.price">
                  {{ po.price | currency }}
                </template>
                <template v-else>
                  {{ $t('pickup_order.free') }}
                </template>
              </li>
            </ul>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  NavBar, Search, PullRefresh, List,
} from 'vant';

Vue.use(NavBar);
Vue.use(Search);
Vue.use(PullRefresh);
Vue.use(List);

export default {
  name: 'PickupOrderList',
  data() {
    return {
      list: [],
      search_parcel_code: '',
      loading: false,
      refreshing: false,
      finished: false,
      pagination: null,
      page: 1,
    };
  },
  mounted() {

  },
  methods: {
    onRefresh() {
      this.list = [];
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onCancelSearch() {
      this.list = [];
      this.page = 1;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      const params = { status: this.$route.params.status };
      if (this.search_parcel_code) {
        this.list = [];
        this.page = 1;
        params.code = this.search_parcel_code;
      } else {
        params.page = this.page;
      }
      this.$api.pickupOrder.get(params).then(({ data: response }) => {
        this.pagination = response.meta.pagination;
        if (this.page < this.pagination.total_pages) {
          this.page++;
        }
        this.list = [...this.list, ...response.data];
        if (this.list.length === this.pagination.total) {
          this.finished = true;
        }
      }).catch(() => {
        this.finished = true;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
  #pickup-order-list {
    .pickup-order-info {
      li {
        .title {
          font-weight: bold;
          &:after {
          content: "：";
          };
        }
      }
    }
  }
</style>
