<template>
  <div id="pickup-order">
    <van-nav-bar
      :title="$t('pickup_order.pickup')"
      left-arrow
      @click-left="() => $router.back()"
    />
    <van-notice-bar
      v-if="config"
      :text="$t('pickup_order.charges', { num: config.pickup_free_orders, price: formatMoney(config.pickup_price) })"
    />
    <van-form @submit="submit">
      <!-- 取件地址 -->
      <van-field
        type="textarea"
        :rows="1"
        :label="$t('pickup_order.pickup_address')"
        :placeholder="$t('pickup_order.select_pickup_address')"
        readonly
        clickable
        :autosize="true"
        :value="sender"
        @click="showSenderPicker = true"
        :rules="[{ required: true, message: $t('pickup_order.select_pickup_address') }]"
      />
      <van-field
        :label="$t('pickup_order.pickup_date')"
        :value="pickupDateFormat"
        :placeholder="$t('pickup_order.pickup_date_placeholder')"
        readonly
        clickable
        @click="showDatePicker = true"
        :rules="[{ required: true, message: $t('pickup_order.pickup_date_placeholder') }]"
      />
      <van-popup v-model="showDatePicker" position="bottom" round>
        <van-datetime-picker
          v-model="pickup_order.date"
          type="month-day"
          :title="$t('pickup_order.pickup_date')"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="dateFormatter"
          @confirm="handleSelectDate"
          @cancel="showDatePicker = false"
        />
      </van-popup>
      <van-field
        :label="$t('pickup_order.pickup_time')"
        :value="pickup_order.time_period"
        :placeholder="$t('pickup_order.pickup_time_placeholder')"
        readonly
        clickable
        @click="showTimePicker = true"
        :rules="[{ required: true, message: $t('pickup_order.pickup_time_placeholder') }]"
      />
      <van-popup v-model="showTimePicker" position="bottom" round>
        <van-picker
          :title="$t('pickup_order.pickup_time')"
          show-toolbar
          :columns="timePeriods"
          @cancel="showTimePicker = false"
          @confirm="handleSelectTime"
        />
      </van-popup>
      <van-popup v-model="showSenderPicker" position="bottom" round>
        <address-list
          type="sender"
          :enable-filter-func="enableAddress"
          :disabled-text="$t('pickup_order.address_disabled_text')"
          @select="handleSelectSenderAddressItem"
          @deleted="handleDeleteSenderAddressitem"
        />
      </van-popup>
      <van-field
        v-model="pickup_order.remark"
        type="textarea"
        :rows="1"
        :label="$t('pickup_order.remark')"
        clickable
        :autosize="true"
        :value="pickup_order.remark"
      />
      <van-field
        :label="$t('pickup_order.select_order')"
      >
        <template #input>
          <van-checkbox-group v-model="pickup_order.orders" class="unpickup-orders-checkbox">
            <van-checkbox
              v-for="order in orders"
              :key="order.id"
              :name="order.id"
            >
              {{ order.reference_id }}
              <br />
              {{ order.receiver_address.name }} {{ order.receiver_address.phone }}
              <br />
              {{ order.receiver_address.district }}
            </van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-submit-bar
        :price="price"
        :button-text="$t('pickup_order.submit')"
        :label="`${$t('pickup_order.price')}：`"
        currency="HK$"
        :disabled="pickup_order.orders.length === 0 || !this.pickup_order.pickup_address_id"
      />
    </van-form>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';
import {
  NavBar, SubmitBar, NoticeBar, DatetimePicker,
} from 'vant';
import AddressList from '@/components/address/AddressList';
import moment from 'moment';
import { showErrorMessage, formatMoney } from '@/helpers';

Vue.use(NavBar);
Vue.use(SubmitBar);
Vue.use(NoticeBar);
Vue.use(DatetimePicker);

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'PickupOrder',
  mounted() {
    this.getOrders();
  },
  data() {
    const m = moment();
    if (m.hour() >= 16) {
      m.add(1, 'd');
    }
    return {
      orders: [],
      sender: '',
      pickup_time: '',
      showSenderPicker: false,
      showDatePicker: false,
      showTimePicker: false,
      minDate: m.toDate(),
      maxDate: moment().add(1, 'M').toDate(),
      pickup_order: {
        pickup_address_id: null,
        date: m.toDate(),
        time_period: '',
        remark: '',
        orders: [],
      },
    };
  },
  computed: {
    ...mapState({
      senderAddress: state => state.address.senderAddress,
      config: state => state.app.config,
    }),
    pickupDateFormat() {
      if (this.pickup_order.date) {
        return moment(this.pickup_order.date).format(DATE_FORMAT);
      }
      return '';
    },
    timePeriods() {
      const now = moment();
      const periods = [
        '9:00-12:00',
        '13:00-16:00',
      ];
      if (!moment(this.pickup_order.date).isAfter(now)) {
        if (now.hour() >= 13) {
          return periods.slice(1);
        }
      }
      return periods;
    },
    price() {
      if (this.pickup_order.orders.length < 10) {
        return 3000;
      }
      return 0;
    },
  },
  methods: {
    formatMoney,
    dateFormatter(type, val) {
      if (type === 'month') {
        return `${val}月`;
      }
      if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    getOrders() {
      const params = {
        status: 'pickup-order-unpickup',
        perpage: 999,
      };
      this.$toast.loading({
        message: this.$t('common.loading'),
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      this.$api.order.get(params).then(({ data: response }) => {
        this.orders = [...response.data];
        this.pickup_order.orders = _.map(this.orders, 'id');
        if (!this.orders.length) {
          this.$dialog.alert({
            title: this.$t('pickup_order.no_orders_tips'),
          }).then(() => {
            this.$router.push('/');
          });
        }
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        this.$toast.clear();
      });
    },
    enableAddress(address) {
      return address.district_path_array.length && address.district_path_array[0] === 3253 && address.address;
    },
    handleSelectSenderAddressItem(item) {
      if (item) {
        this.pickup_order.pickup_address_id = item.id;
        this.sender = `${item.name} ${item.tel}\n${item.address} `;
      } else {
        this.pickup_order.pickup_address_id = null;
        this.sender = '';
      }
      this.showSenderPicker = false;
    },
    handleDeleteSenderAddressitem(id) {
      if (id === this.pickup_order.pickup_address_id) {
        this.handleClickSenderAddressItem(false);
      }
    },
    handleSelectDate(date) {
      this.pickup_order.date = date;
      this.showDatePicker = false;
      // eslint-disable-next-line
      this.pickup_order.time_period = this.timePeriods[0];
    },
    handleSelectTime(time) {
      this.pickup_order.time_period = time;
      this.showTimePicker = false;
    },
    submit() {
      this.$toast.loading({
        message: this.$t('common.saving'),
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      const params = { ...this.pickup_order };
      params.date = moment(this.pickup_order.date).format(DATE_FORMAT);
      this.$api.pickupOrder.create(params).then(({ data: response }) => {
        this.$router.push({ name: 'pickup-order-detail', params: { id: response.data.id } });
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        this.$toast.clear();
      });
    },
  },
  components: {
    AddressList,
  },
};
</script>

<style lang="scss">
  @import "../../style/variables";

  #pickup-order {
    padding-bottom: 34px;
    .unpickup-orders-checkbox {
      width: 100%;
      .van-checkbox {
        margin-bottom: .25rem;
        padding: .25rem 0;
        border-bottom: 1px dashed $gray-4;
      }
    }
  }
</style>
